<template>
  <div>
    <van-share-sheet
      @cancel="cancel"
      @click-overlay="cancel"
      v-model="share.boo"
      title="立即分享给好友"
      :options="share.options"
      @select="onSelect"
    />

    <van-overlay :show="wechatShare.boo" @click="wechatShare.boo = false">
      <div class="codeWrap">
        <img src="@image/share/weixin-share.png" class="codeImg" />
      </div>
    </van-overlay>

    <oCodeImg :boo.sync="codeImgBoo" :g_id="g_id"></oCodeImg>
  </div>
</template>

<script>
import oCodeImg from "./codeImg.vue";
import { Toast, ShareSheet, Overlay } from "vant";
import { getQueryVariable } from "@get/util";
export default {
  props: {
    boo: {
      default: false,
      type: Boolean,
    },
    g_id: {
      default: "",
    },
    goodsName: {
      default: "",
      type: String,
    },
  },
  components: {
    oCodeImg,
    vanOverlay: Overlay,
    vanShareSheet: ShareSheet,
  },
  created() {
    this.share.boo = this.boo;
  },
  watch: {
    g_id() {
      this.windowHref = this.checkHref();
    },
    boo() {
      if (this.boo == true) {
        this.share.boo = true;
      }
    },
  },
  data() {
    return {
      codeImgBoo: false,
      wechatShare: {
        boo: false,
      },
      share: {
        boo: false,
        options: [
          [
            { name: "微信", icon: "wechat" },
            {
              name: "朋友圈",
              icon:
                "http://image.shop.19jiang.cn/home/mofang/alioss_60540898563bb.png",
            },
            // "http://image.shop.19jiang.cn/home/mofang/alioss_605408029d9a0.png"

            { name: "复制链接", icon: "link" },
            { name: "二维码", icon: "qrcode" },
          ],
        ],
      },
      windowHref: window.location.href,
    };
  },
  methods: {
    checkHref() {
      let id = getQueryVariable("goods_id");
      let href = window.location.href;
      let s = href.replace(id, this.g_id);
      return s;
    },
    cancel() {
      this.share.boo = false;
      this.$emit("update:boo", false);
    },
    onSelect(option) {
      this.share.boo = false;
      if (option.icon == "qrcode") {
        this.codeImgBoo = true;
      } else if (option.icon == "link") {
        let copy = `严选了一个*十九匠*产品给你

【${this.goodsName}】

【宝藏传送门】${this.windowHref}`;

        this.$copyText(copy).then(
          function(e) {
            Toast("十九匠商城:已复制到剪切板");
          },
          function(e) {
            Toast("微信:复制失败");
          }
        );
      } else {
        this.wechatShare.boo = true;
      }

      this.$emit("update:boo", false);
    },
  },
};
</script>
