<template>
  <div>
    <van-action-sheet
      closeable
      v-model="skuShow"
      v-if="spec"
      style="max-height: 90%"
    >
      <div class="spec-sku">
        <div class="cur_goods flex-container" v-if="spec && spec[speced_inedx]">
          <img
            @click="getZoomImage(spec[speced_inedx].goods_image_path)"
            :src="spec[speced_inedx].goods_image_path"
            class="spec_img"
            v-if="spec[speced_inedx].goods_image_path"
          />
          <div class="spec_info flex-container">
            <div class="spec_price">
              <span class="price"
                ><i>￥</i
                >{{
                  checkedInfo.activePrice
                    ? checkedInfo.activePrice
                    : checkedInfo.goods_price
                }}</span
              >
              <span class="mark">￥{{ spec[speced_inedx].marketprice }}</span>
            </div>
            <template v-if="spec[speced_inedx]">
              <span class="spec_stock" v-if="spec[speced_inedx].stock > 20"
                >剩余{{ spec[speced_inedx].stock }}</span
              >
              <span
                style="color: #ee0a24"
                class="spec_stock"
                v-else-if="
                  spec[speced_inedx].stock >= 1 &&
                    spec[speced_inedx].stock <= 20
                "
                >剩余{{ spec[speced_inedx].stock }}</span
              >
              <span class="spec_stock" v-else style="color: #ee0a24"
                >已售罄</span
              >
            </template>
            <span class="spec_tip">
              {{
                spec[speced_inedx].spvalue_name
                  ? `已选择 ${spec[speced_inedx].spvalue_name}`
                  : "请选择 规格"
              }}</span
            >
          </div>
        </div>

        <div class="sku-scrol">
          <div class="sku-ul">
            <div class="sku-ul-h1">规格</div>
            <div class="sku-mode flex-container">
              <template
                v-if="detail.big_sku_mode == 0 || detail.big_sku_mode == 1"
              >
                <div
                  @click="checkSku(index, item.goods_id, item)"
                  :class="{ 'small-act': speced_inedx == index }"
                  class="small-img-text-list"
                  v-for="(item, index) in spec"
                  :key="item.goods_id"
                >
                  <div class="flex-container">
                    <img
                      :src="item.goods_image_path"
                      v-if="detail.big_sku_mode == 1"
                    />
                    <p>{{ item.spvalue_name }}</p>
                    <!-- <van-stepper v-model="sku_num" :max="sku_max_num" /> -->
                  </div>
                </div>
              </template>

              <div
                v-else
                class="big-img-text-list"
                :class="{ 'big-act': speced_inedx == index }"
                v-for="(item, index) in spec"
                @click="checkSku(index, item.goods_id, item)"
                :key="item.goods_id"
              >
                <div class="flex-container">
                  <img :src="item.goods_image_path" />
                  <p class="flex-container">
                    <span>{{ item.spvalue_name }}</span>
                  </p>
                </div>
                <!-- <van-stepper v-model="sku_num" :max="sku_max_num" /> -->
              </div>
            </div>
          </div>

          <div class="sku-num flex-container">
            <p class="flex1">我要买</p>
            <span v-if="detail.snap_up == 1 && detail.upper_limit !== 0"
              >(1件起售，限购{{ detail.upper_limit - detail.snap_up_limit }}/{{
                detail.upper_limit
              }}件)</span
            >
            <van-stepper
              v-model="sku_num"
              :max="sku_max_num"
              :disabled="detail.promotion_type == 'presale'"
            />
          </div>
        </div>

        <div class="sku-buy" v-if="spec[speced_inedx]">
          <!-- -->
          <div class="tip" v-if="spec[speced_inedx].stock == 0">商品已售罄</div>
          <div
            class="tip"
            v-else-if="checkedInfo.activeTitle"
            style="color: #ee0a24"
          >
            该商品正在参加 {{ checkedInfo.activeTitle }}
          </div>
          <div
            class="tip"
            v-else-if="detail.snap_up_limit == 0"
            style="color: #ee0a24"
          >
            限购数量已达上限，不能再购买了哦~
          </div>
          <div class="tip" v-else-if="spec[speced_inedx].stock < 20">
            商品不多，赶快购买吧~
          </div>

          <div class="sku-buy-btn flex-container">
            <template>
              <template v-if="spec[speced_inedx].stock == 0">
                <div
                  @click="toastTip('商品卖完了,看看其他吧~')"
                  class=" btn btn1 over"
                >
                  加入购物车
                </div>
                <div
                  @click="toastTip('商品卖完了,看看其他吧~')"
                  class=" btn btn2 over"
                >
                  售罄
                </div>
              </template>
              <template v-else-if="detail.snap_up_limit == 0">
                <div @click="toastTip('限购已达上限')" class="btn btn1 over">
                  加入购物车
                </div>
                <div @click="toastTip('限购已达上限')" class="btn btn2 over">
                  限购已达上限
                </div>
              </template>
              <template v-else>
                <div
                  v-if="detail.promotion_type == 'presale'"
                  class="btn btn3"
                  @click="goToPreSale(spec[speced_inedx].goods_id)"
                >
                  <!-- 限购 -->
                  支付定金
                </div>
                <template v-else>
                  <div
                    class="btn btn1"
                    @click="addShopping(spec[speced_inedx].goods_id)"
                  >
                    加入购物车
                  </div>
                  <div
                    class="btn btn2"
                    @click="goNowShopping(spec[speced_inedx].goods_id)"
                  >
                    立即购买
                  </div>
                </template>
              </template>
            </template>
          </div>
        </div>
      </div>
    </van-action-sheet>

    <van-goods-action>
      <van-goods-action-icon icon="shop-o" text="店铺" @click="gotoStore" />
      <van-goods-action-icon icon="chat-o" text="客服" @click="goKefu" />
      <template>
        <van-goods-action-icon
          icon="cart-o"
          text="购物车"
          v-if="$store.state.cart.cart_num === 0"
          @click="goCart"
        />
        <van-goods-action-icon
          icon="cart-o"
          text="购物车"
          :badge="$store.state.cart.cart_num"
          v-else
          @click="goCart"
        />
      </template>

      <template v-if="detail.is_optional == 0">
        <!--  -->
        <van-goods-action-button
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          type="danger"
          text="立即购买"
          :style="{
            opacity: optionalColor,
          }"
          @click="goBuyOptional"
        />
      </template>

      <template v-else>
        <van-goods-action-button
          color="#FF7C48"
          type="warning"
          text="加入购物车"
          @click="goBuy"
        />
        <van-goods-action-button
          color="#FF4848"
          type="danger"
          text="立即购买"
          @click="goBuy"
        />
      </template>
    </van-goods-action>

    <oKefu :boo.sync="kefuBoo"></oKefu>
  </div>
</template>

<script>
import {
  ImagePreview,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Sku,
  ActionSheet,
  Stepper,
  Toast,
} from "vant";

import oKefu from "@view/me/view/chat/popup.vue";
import { mapState } from "vuex";
import { getCartAdd, getCartNum } from "@view/cart/js/";
import merge from "webpack-merge";
export default {
  props: {
    checkedInfo: {
      require: true,
    },
    detail: {
      tyep: Object,
      require: true,
    },
    optionalSku: {
      default: function() {
        return [];
      },
    },
  },
  created() {
    this.init();
  },
  data() {
    return {
      cart_badge: 0,
      sku_num: 1,
      sku_max_num: 99999,
      speced_inedx: 0,
      spec: [],
      kefuBoo: false,
      skuShow: false,
      store_id: 2,
      optionalColor: 0.3,
    };
  },
  watch: {
    detail: {
      deep: true,
      handler() {
        this.init();
      },
    },
    optionalSku: {
      handler() {
        let num = this.returnOptionalNum();
        if (num != this.detail.is_optional_maxnum) {
          this.optionalColor = 0.3;
        } else {
          this.optionalColor = 1;
        }
      },
      deep: true,
    },
  },
  methods: {
    goKefu() {
      if (window.__wxjs_environment === "miniprogram") {
        wx.miniProgram.navigateTo({
          url: "../chat/index",
        });
        return false;
      } else {
        this.kefuBoo = true;
      }
    },
    returnOptionalNum() {
      let p = this.optionalSku;
      let num = 0;

      for (let index = 0; index < p.length; index++) {
        num += p[index].sku_num;
      }
      return num;
    },
    goToPreSale() {
      //跳转到定金支付
      this.$router.push({
        name: "preSaleStep1",
        query: {
          goods_id: this.detail.goods_id,
        },
      });
    },
    goBuyOptional() {
      //多选
      let num = this.returnOptionalNum();
      if (num != this.detail.is_optional_maxnum) {
        Toast(`至少选择${this.detail.is_optional_maxnum}件才可以下单哦~`);
      } else {
        let cart_id = "";
        for (let index = 0; index < this.optionalSku.length; index++) {
          if (this.optionalSku[index].sku_num != 0) {
            cart_id += `${this.optionalSku[index].goods_id}|${
              this.optionalSku[index].sku_num
            }${index != this.optionalSku.length - 1 ? "," : ""}`;
          }
        }
        let params = {
          buy_now: 1,
          cart_id,
        };
        this.$router.replace({ name: "Buy", query: params });
      }
    },
    toastTip(title) {
      Toast(title);
    },
    async getCartCount() {
      this.$store.dispatch("doSetCartNum", "carnum");
    },
    getIsGradeLimit() {
      /****
       *
       * @method getIsGradeLimit  检查是否登记限购
       */
      if (
        this.token &&
        this.user &&
        this.user["19jiang_inviter_level_id"] &&
        this.detail?.tags.length != 0
      ) {
        let text = "仅限于",
          boo = false;
        this.detail.tags.forEach((id, index) => {
          if (id == this.user["19jiang_inviter_level_id"]) {
            boo = true;
          } else {
            let items = this.gradeLeven.find(
              (item) => id == item.inviterclass_id
            );
            text += `${items.inviterclass_name}${
              this.detail.tags.length - 1 == index ? "" : "，"
            }`;
          }
        });
        if (!boo) {
          text += "购买";
          Toast(text);
          return false;
        }
      }
      return true;
    },
    goNowShopping(goods_id) {
      //立即购买
      let params = {
        buy_now: 1,
        cart_id: goods_id + "|" + this.sku_num,
      };
      if (this.getIsGradeLimit()) {
        this.$router.push({ name: "Buy", query: params });
      }
    },

    // 加入购物车
    async addShopping(goods_id) {
      if (this.getIsGradeLimit()) {
        let data = await getCartAdd(goods_id, this.sku_num, "");
        this.skuShow = false;
        this.getCartCount();
        Toast("成功");
      }

      // this.erweima_boo = !this.erweima_boo;
    },
    gotoStore() {
      this.$router.push({
        name: "HomeIndex",
      });
    },
    checkSku(index, id, item) {
      if (this.speced_inedx == index) {
        this.getZoomImage(item.goods_image_path);
      } else {
        this.$emit("getData", id);
        // 刷新链接
        // this.$router.push({
        //   query: merge(this.$route.query, {
        //     goods_id: id,
        //   }),
        // });
      }
    },
    init() {
      this.spec = this.detail?.goods_spec2 || [];
      if (this.spec) {
        this.check_thisSpec();
      }
      // 设置最大限购数量
      if (this.spec[this.speced_inedx]) {
        if (this.detail.snap_up === 1 && this.detail.upper_limit !== 0) {
          // 如果是抢购 并且 限购数量不为0
          if (this.spec[this.speced_inedx].stock < this.detail.snap_up_limit) {
            this.sku_max_num = this.spec[this.speced_inedx].stock;
          } else {
            this.sku_max_num = this.detail.snap_up_limit;
          }
        } else {
          this.sku_max_num = this.spec[this.speced_inedx].stock;
        }
      }
    },
    getZoomImage(image) {
      ImagePreview([image]);
    },
    check_thisSpec() {
      let s = this.spec;
      for (let index = 0; index < s.length; index++) {
        if (this.detail.goods_id == s[index].goods_id) {
          this.speced_inedx = index;
          break;
        }
      }
    },
    goBuy() {
      this.skuShow = true;
    },
    goCart() {
      this.$router.push({
        name: "Cart",
        params: { type: 0 },
      });
    },
  },
  components: {
    oKefu,
    vanActionSheet: ActionSheet,
    vanGoodsAction: GoodsAction,
    vanGoodsActionIcon: GoodsActionIcon,
    vanGoodsActionButton: GoodsActionButton,
    vanStepper: Stepper,
    vanSku: Sku,
  },

  computed: {
    ...mapState({
      token: (state) => state.user.token,
      user: (state) => state.user.info,
      gradeLeven: (state) => state.common.gradeLeven,
    }),
  },
};
</script>

<style lang="scss" scoped>
// @use "sass:math";
@import "~@css/mixin.scss";
.van-goods-action {
  z-index: 999;
}
.spec-sku {
  padding: rem(32);
  box-sizing: border-box;
  background: #fff;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
.cur_goods {
  border-bottom: 1px solid #ebedf0;
  padding-bottom: rem(30);
  .spec_img {
    width: rem(200);
    height: rem(200);
    display: block;
    border-radius: rem(8);
  }
  .spec_info {
    flex-direction: column;
    align-self: flex-end;
    margin-left: rem(24);

    .spec_stock {
      margin-top: rem(16);
    }
    .spec_stock,
    .spec_tip {
      display: block;
      color: #969799;
      font-size: rem(24);
    }
    .spec_tip {
      margin-top: rem(10);
    }
    .spec_price {
      .price {
        color: #ee0a24;
        font-size: rem(44);
        i {
          font-size: rem(32);
        }
      }
      .mark {
        margin-left: rem(16);
        color: #969799;
        font-size: rem(24);
        text-decoration: line-through;
      }
    }
  }
}

.sku-scrol {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  height: rem(600);
  min-height: rem(600);
}

.sku-ul {
  margin-top: rem(30);
  border-bottom: 1px solid #ebedf0;
  padding-bottom: rem(30);
  .sku-ul-h1 {
    padding-bottom: rem(24);
    color: #323233;
    font-size: rem(30);
  }
}
.sku-mode {
  flex-wrap: wrap;
  .small-act {
    background: #fde7ea !important;
    p {
      color: #ee0a24 !important;
    }
  }

  .big-act {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 99%;
      height: 99%;
      border: 1px solid #ee0a24;
      content: "";
      border-radius: rem(8);
    }
    // border: 1px solid #ee0a24;
    box-sizing: border-box;
    p {
      background: #fde7ea !important;
      color: #ee0a24 !important;
    }
  }

  .small-img-text-list {
    margin-right: rem(30);
    margin-bottom: rem(24);
    background: #f7f8fa;
    padding: rem(10);
    border-radius: rem(8);

    .flex-container {
      align-items: center;
      justify-content: center;
      img {
        display: block;
        border-radius: rem(4);
        width: rem(50);
        height: rem(50);
      }
      p {
        margin-left: rem(16);
        color: #323233;
        font-size: rem(28);
        margin-right: rem(10);
      }
    }
  }

  .big-img-text-list {
    position: relative;
    width: rem(200);
    margin-right: rem(24);
    border-radius: rem(8);
    margin-bottom: rem(24);
    > .flex-container {
      flex-direction: column;

      img {
        box-sizing: border-box;
        border-radius: rem(8) rem(8) 0 0;

        width: rem(200);
        height: rem(200);
        display: block;
      }
      p {
        background: #f7f8fa;
        width: 100%;
        height: rem(80);
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 0 0 rem(8) rem(8);
        span {
          @include ell2;
        }
      }
    }
  }
}

.sku-num {
  justify-content: center;
  align-items: center;
  padding: rem(24) 0;
  p {
    color: #323233;
    font-size: rem(30);
  }
  span {
    color: #ee0a24;
    font-size: rem(24);
    margin-right: rem(12);
  }
}

.sku-buy {
  .tip {
    margin-top: rem(16);
    color: #969799;
    text-align: center;
  }
}
.sku-buy-btn {
  width: 100%;

  .btn {
    width: 50%;
    height: rem(80);
    font-weight: 500;
    font-size: rem(30);
    line-height: rem(80);
    text-align: center;
    color: #fff;
  }

  .btn1 {
    border-radius: rem(40) 0 0 rem(40);
    // border-radius: rem(math.div(80, 2)) 0 0 rem(math.div(80, 2));
    background: linear-gradient(to right, #ffd01e, #ff8917);
  }
  .btn2 {
    border-radius: 0 rem(40) rem(40) 0;
    // border-radius: 0 rem(math.div(80, 2)) rem(math.div(80, 2)) 0;
    background: linear-gradient(to right, #ff6034, #ee0a24);
  }

  .btn3 {
    width: 100%;
    border-radius: rem(40);
    // border-radius: 0 rem(math.div(80, 2)) rem(math.div(80, 2)) 0;
    background: linear-gradient(to right, #ff6034, #ee0a24);
  }

  .over {
    color: rgba(255, 255, 255, 0.8);
    // background: linear-gradient(to right, #a7a8bd, #ede3e7) !important;

    opacity: 0.3;
  }
}
</style>
