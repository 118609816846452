<template>
  <div class="loading">
    <div class="g"></div>
    <img
      src="http://image.shop.19jiang.cn/home/mofang/alioss_6180b240557e6.png"
      class="logo"
    />
    <div class="content">
      <h4></h4>
      <h4></h4>
      <h4></h4>
      <h5></h5>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.loading {
  position: relative;
  .logo {
    width: 100%;
    height: rem(600);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .g {
    width: 100%;
    height: rem(600);

    // animation-delay: 0.05s;
  }
  .content {
    padding: rem(24);
    h5 {
      width: 100%;
      height: rem(300);
      animation-delay: 0.02s;
    }
    h4 {
      animation-delay: 0.01s;

      width: 100%;
      height: rem(50);
      margin-bottom: rem(20);
    }
  }
  .g,
  h4,
  h5 {
    background-color: #ebebeb;
    background: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      #ebebeb;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
  }
}

@keyframes loading {
  to {
    background-position-x: -40%;
  }
}
</style>
