<template>
  <div>
    <div>
      <ul class="dShop_Right-ul">
        <div class="g-s-l-title flex-container">
          <span class="flex1">99元任选专区</span>
          <i>任选5件</i>
        </div>
        <li
          class="flex-container"
          v-for="(item, index) in spec"
          :key="item.goods_id"
        >
          <img
            :src="item.goods_image_path"
            class="mainImg"
            @click="
              imgshow = true;
              zoomImgArr = item.goods_image_path;
            "
          />
          <div class="data">
            <div class="title">
              <!-- <img src="@image/shop/Rectangle 10.png" class="tip" /> -->
              {{ item.spvalue_name }}
            </div>
            <span class="subTitle">&nbsp;</span>
            <div class="price flex-container">
              <span>￥{{ setPrice(item.price) }}</span>
              <i
                v-if="
                  item.marketprice != item.price &&
                    setPrice(item.price).length < 6
                "
                >￥{{ setPrice(item.price) }}</i
              >
              <div class="action flex-container flex1">
                <template>
                  <img
                    src="@image/shop/shop_action_subtract.png"
                    @click="addChcek(index, 'sub')"
                    :style="{
                      opacity: optionalSku[index].sku_num > 0 ? '1' : '0.3',
                    }"
                  />
                  <b>{{ optionalSku[index].sku_num }}</b>
                </template>
                <template>
                  <img
                    src="@image/shop/shop_action_add.png"
                    @click="addChcek(index, 'add')"
                    :style="{
                      opacity: check_num >= sku_max_num ? '0.3' : '1',
                    }"
                  />
                </template>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <van-popup
      v-model="imgshow"
      get-container="body"
      :style="{ width: '100%' }"
      closeable
      close-on-popstate
    >
    
      <img :src="zoomImgArr" style="width: 100%" />

    </van-popup>
  </div>
</template>

<script>
import { Stepper, Toast, Popup } from "vant";

export default {
  components: {
    vanStepper: Stepper,
    vanPopup: Popup,
  },
  props: {
    detail: {},
    optionalSku: {
      default: function() {
        return [];
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  data() {
    return {
      imgshow: false,
      sku_max_num: 5,
      zoomImgArr: "",
      spec: [],
      check_num: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    addChcek(index, sub) {
      if (sub == "add") {
        if (this.check_num >= this.sku_max_num) {
          Toast(`最多任选${this.sku_max_num}件`);
          return false;
        } else {
          this.check_num += 1;
          this.optionalSku[index].sku_num += 1;
        }
      } else {
        if (this.optionalSku[index].sku_num != 0 && this.check_num != 0) {
          this.check_num -= 1;
          this.optionalSku[index].sku_num -= 1;
        } else {
          Toast(`不能再少了`);
        }
      }
    },
    fChangeOptional(i) {
      let o = JSON.parse(JSON.stringify(this.optionalSku));
      // console.log(this.optionalSku[i].sku_num);
      if (this.check_num >= this.sku_max_num) {
        // this.$set(this.optionalSku, [i], {});

        this.optionalSku[i] = {
          goods_id: o[i].goods_id,
          sku_num: o[i].sku_num--,
        };
        Toast(`只能任选${this.sku_max_num}件`);
        return false;
      }
      let num = 0;
      for (let index = 0; index < o.length; index++) {
        num += o[index].sku_num;
      }
      this.check_num = num;
    },
    setPrice(val) {
      let arr = toString(val).split(".");
      if (arr[1] == "00") {
        return arr[0];
      } else {
        return val;
      }
    },
    init() {
      this.spec = this.detail?.goods_spec2 || [];
      if (this.spec && this.spec.length != 0) {
        for (let index = 0; index < this.spec.length; index++) {
          this.optionalSku.push({
            goods_id: this.spec[index].goods_id,
            sku_num: 0,
          });
        }
        if (this.detail.is_optional == 1) {
          this.sku_max_num = this.detail.is_optional_maxnum;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.dShop_Right-ul {
  width: 94%;
  background: #fff;
  margin: rem(20) auto 0;
  border-radius: rem(12);
  li {
    width: 100%;
    height: rem(200);
    align-items: center;

    .mainImg {
      width: rem(140);
      height: rem(140);
      border-radius: rem(8);
      margin: 0 rem(20);
    }
    .data {
      width: 100%;
      @include ell;
      .tip {
        display: inline-block;
        width: rem(58);
        height: rem(35);
        margin-right: rem(10);
      }
      .title {
        @include ell;
        font-size: rem(30);
        width: 93%;
        font-weight: bold;
      }
      .subTitle {
        display: block;
        font-size: rem(28);
        color: #999;
        padding-bottom: rem(11);
      }
      .price {
        span {
          display: inline-block;
          color: #fb7e1c;
          font-size: rem(34);
          padding-right: rem(6);
          // align-self: center;
        }
        i {
          text-decoration-line: line-through;
          color: #c9c9c9;
          font-size: rem(26);
          // align-self: center;
          line-height: rem(56);
        }
      }
    }
    .action {
      justify-content: flex-end;
      margin-right: rem(30);

      img {
        display: block;
        width: rem(44);
        height: rem(44);
      }
      b {
        color: #333;
        font-size: rem(30);
        margin: 0 rem(14);
      }
    }
  }
}

.g-s-l-title {
  padding: rem(30) rem(24);
  align-items: center;
  span {
    font-size: rem(36);
    font-weight: bold;
    letter-spacing: 2px;
  }
  i {
    color: #a6a6a6;
  }
}
</style>
