<template>
  <div class="goods_argInfo">
    <div class="g-a-list flex-container">
      <span class="g-a-left">发货</span>
      <div class="flex-container flex1">
        <img class="g-a-icon_add" src="@image/order/order_pos.png" />
        <span class="g-a-address flex1"
          >{{ info.goods_hair_info && info.goods_hair_info.area_name }}
          <i></i>
        </span>
        <span class="g-a-sale">
          总销{{ detail.goods_salenum + detail.goods_vr_salenum }}
        </span>
      </div>
    </div>
    <div class="g-a-list flex-container">
      <span class="g-a-left">规格</span>
      <div class="flex-container flex1">
        <!-- <span>请选择 产品</span> -->
        <span>已选择 {{ filtersName() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    filtersName() {
      let title = this.detail.goods_name;
      let sku = this.detail.goods_spec;
      for (const key in sku) {
        return sku[key];
      }
      // for (const key in sku) {
      //   if (title.lastIndexOf(sku[key]) != -1) {
      //     return title.slice(title.indexOf(sku[key]), title.lastIndexOf(sku[key]));
      //   }
      // }
    },
  },
  created() {},
  props: {
    info: {
      type: Object,
    },
    detail: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.goods_argInfo {
  border-radius: rem(20);
  box-sizing: border-box;
  padding: rem(14) rem(12);
  background: #fff;
  width: rem(710);
  margin: 0 auto;
  margin-top: rem(20);
  .g-a-list {
    padding: rem(20) 0;
    .g-a-left {
      color: #a6a6a6;
      margin-left: rem(12);
      width: rem(80);
    }
    .g-a-icon_add {
      width: rem(22);
      height: rem(26);
      margin-top: rem(4);
      margin-right: rem(8);
    }
    .g-a-address {
      i {
        display: inline-block;
        margin: 0 rem(16);
      }
    }
    .g-a-sale {
      color: #a6a6a6;
      margin-right: rem(20);
    }
  }
}
</style>
