<template>
  <div>
    <oSkeleton v-if="showPage == 0"> </oSkeleton>
    <div class="HomeGoods" v-if="errorText.length == 0 && showPage == 1">
      <!-- 轮播图 -->
      <oSwiper :imgs="goodsInfo.goods_image"></oSwiper>
      <!-- //弹出SKU -->
      <oGoodsAction
        :detail="details"
        @getData="getData"
        :checkedInfo="checkedInfo"
        :optionalSku="optionalSku"
      ></oGoodsAction>
      <!-- 头部信息 如价格 标题 -->
      <oGoodsHeader :detail="details" :g_id="g.id"></oGoodsHeader>

      <oGoodsOptional
        :detail="details"
        :optionalSku="optionalSku"
        v-if="details.is_optional == 0"
      ></oGoodsOptional>
      <!-- <oGoodsArgInfo :detail="details" :info="goodsInfo"></oGoodsArgInfo> -->
      <oGoodsReview :c_id="details.goods_commonid"></oGoodsReview>
      <oGoodsCombination :detail="details"></oGoodsCombination>
      <lazy-component>
        <oGoodsShop
          :storeInfo="goodsInfo.store_info"
          :info="goodsInfo"
        ></oGoodsShop>
      </lazy-component>
      <lazy-component>
        <ogoodsDetail :detail="details"> </ogoodsDetail>
      </lazy-component>
    </div>

    <!-- 报错 -->
    <van-empty :description="errorText" v-if="showPage == 2" image="error">
      <van-button round class="bottom-button " @click="$router.go(-1)"
        >返回</van-button
      >
      <van-button
        round
        type="primary"
        class="bottom-button"
        @click="$router.push({ name: 'HomeIndex' })"
        >回到首页</van-button
      >
    </van-empty>
  </div>
</template>

<script>
import oSwiper from "./components/swiper.vue";
import { Toast, Empty, Button } from "vant";
import oGoodsAction from "./components/goodsAction.vue";
import oGoodsHeader from "./components/goodsHeader.vue";
import oGoodsArgInfo from "./components/goodsArgInfo.vue";
import oGoodsShop from "./components/goodsShop.vue";
import ogoodsDetail from "./components/goodsDetail.vue";
import oGoodsReview from "./components/goodsReview.vue";
import oGoodsCombination from "./components/goodsCombination.vue"; // 优惠套餐
import oGoodsOptional from "./components/goodsOptional"; //任选专区
import oSkeleton from "./components/skeleton/";
import { post } from "@get/http";
import { getGoodsDetail } from "./js/";
import { mapState } from "vuex";
export default {
  components: {
    oSkeleton,
    vanEmpty: Empty,
    vanButton: Button,
    oSwiper,
    oGoodsAction,
    oGoodsHeader,
    oGoodsArgInfo,
    oGoodsShop,
    ogoodsDetail,
    oGoodsReview,
    oGoodsCombination,
    oGoodsOptional,
  },
  provide() {
    return {
      goods: {
        g: this.g,
      },
    };
  },
  watch: {
    "$route.query.goods_id"() {
      if (this.$route.query?.goods_id) {
        this.showPage = 0;
        this.getData(this.$route.query.goods_id);
      }
    },
  },
  data() {
    return {
      showPage: 0,
      errorText: "",
      checkedInfo: {},
      productId: this.$route.query.goods_id,
      goodsInfo: {}, //整体goods信息
      details: {},
      g: {
        id: "",
      },
      optionalSku: [], //多选sku  任选规格
    };
  },
  created() {
    this.getData(this.$route.query.goods_id);
  },
  computed: mapState({
    // productDetail: (state) => state.goodsdetail.detailInfo,
    currentProductId: (state) => state.goodsdetail.currentProductId,
    token: (state) => state.user.token, //token
    isPreviewPicture: (state) => state.goodsdetail.isPreviewPicture,
    swipeId: (state) => state.goodsdetail.swipeId,
    promoPopstatus: (state) => state.goodsdetail.promoPopstatus,
    config: (state) => state.config.config,
    storeInfo: (state) => state.goodsdetail.storeInfo,
    user: (state) => state.user.info,
  }),

  methods: {
    getVipPrice() {
      let g = this.goodsInfo.goods_info;
      if (!g?.promotion_type) {
        //如果是活动产品则不进行价格变化
        if (this.token) {
          //是否登录 未登陆不进行价格变化
          if (
            g?.goods_mgdiscount &&
            g?.goods_mgdiscount?.length != 0 &&
            this.user?.level
          ) {
            //是否有折扣价
            g.goods_price = (
              g.goods_price *
                (g.goods_mgdiscount[this.user.level].level_discount / 10) -
              (g.goods_mgdiscount[this.user.level].level_derate || 0)
            ).toFixed(2);
            return g.goods_price;
          }
          return false;
        } else {
          return false;
        }
      }
    },
    async getData(id) {
      if (this.showPage == 1) {
        let t = Toast.loading({
          message: "获取中",
          // forbidClick: true,
          duration: 0,
        });
      }
      let res = await getGoodsDetail(id ? id : this.productId, this.token);

      if (res.code == 10000) {
        let result = res.result;
        this.goodsInfo = result;
        this.details = result.goods_info;

        for (const key in result.goods_info.goods_spec) {
          document.title = result.goods_info.goods_spec[key];
        }
        // document.title = this.details.goods_name;
        let vip = this.getVipPrice();
        let g = this.goodsInfo.goods_info;
        this.checkedInfo = {
          activeTitle: g.title || "",
          activePrice: g.promotion_price || "",
          goods_price: vip ? vip : g.goods_price || "",
        };
        this.g.id = g.goods_id;
        this.showPage = 1;
        this.errorText = "";
        Toast.clear();
      } else {
        Toast("商品出错");
        this.errorText = res.message;
        this.showPage = 2;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:root {
  --loading-grey: #ededed;
}

body {
  background-color: #f6f6f6;
  font-family: Helvetica;
  font-size: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100vh;
}

.card {
  width: 320px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
}

.image {
  height: 200px;
}

.image img {
  display: block;
  width: 100%;
  height: inherit;
  object-fit: cover;
}

.content {
  padding: 2rem 1.8rem;
}

h4 {
  margin: 0 0 1rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.description {
  font-size: 1rem;
  line-height: 1.4rem;
}

.loading .image,
.loading h4,
.loading .description {
  background-color: var(--loading-grey);
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    var(--loading-grey);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.loading h4 {
  min-height: 1.6rem;
  border-radius: 4px;
  animation-delay: 0.05s;
}

.loading .description {
  min-height: 4rem;
  border-radius: 4px;
  animation-delay: 0.06s;
}
</style>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.my-swipe {
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}

.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}

::v-deep {
  .no-p {
    .van-skeleton {
      padding: 0;
    }
  }
  .s-swiper {
    .van-skeleton__title {
      width: 100%;
      height: rem(600);
    }
  }
}

.bottom-button {
  display: block;
  margin: 0 auto;
  margin-bottom: rem(30);
  width: rem(300);
}
</style>
