var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('ul',{staticClass:"dShop_Right-ul"},[_vm._m(0),_vm._l((_vm.spec),function(item,index){return _c('li',{key:item.goods_id,staticClass:"flex-container"},[_c('img',{staticClass:"mainImg",attrs:{"src":item.goods_image_path},on:{"click":function($event){_vm.imgshow = true;
            _vm.zoomImgArr = item.goods_image_path;}}}),_c('div',{staticClass:"data"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.spvalue_name)+" ")]),_c('span',{staticClass:"subTitle"},[_vm._v(" ")]),_c('div',{staticClass:"price flex-container"},[_c('span',[_vm._v("￥"+_vm._s(_vm.setPrice(item.price)))]),(
                item.marketprice != item.price &&
                  _vm.setPrice(item.price).length < 6
              )?_c('i',[_vm._v("￥"+_vm._s(_vm.setPrice(item.price)))]):_vm._e(),_c('div',{staticClass:"action flex-container flex1"},[[_c('img',{style:({
                    opacity: _vm.optionalSku[index].sku_num > 0 ? '1' : '0.3',
                  }),attrs:{"src":require("@image/shop/shop_action_subtract.png")},on:{"click":function($event){return _vm.addChcek(index, 'sub')}}}),_c('b',[_vm._v(_vm._s(_vm.optionalSku[index].sku_num))])],[_c('img',{style:({
                    opacity: _vm.check_num >= _vm.sku_max_num ? '0.3' : '1',
                  }),attrs:{"src":require("@image/shop/shop_action_add.png")},on:{"click":function($event){return _vm.addChcek(index, 'add')}}})]],2)])])])})],2)]),_c('van-popup',{style:({ width: '100%' }),attrs:{"get-container":"body","closeable":"","close-on-popstate":""},model:{value:(_vm.imgshow),callback:function ($$v) {_vm.imgshow=$$v},expression:"imgshow"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.zoomImgArr}})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"g-s-l-title flex-container"},[_c('span',{staticClass:"flex1"},[_vm._v("99元任选专区")]),_c('i',[_vm._v("任选5件")])])}]

export { render, staticRenderFns }