<template>
  <div>
    <div v-for="(item, index) in combination" class="g-shop">
      <div class="g-s-l-title flex-container">
        <span class="flex1">优惠套餐</span>
        <i>{{ cPrice[index].name }}</i>
      </div>
      <div class="g-shop-love flex-container">
        <div
          class="g-s-l-list"
          v-for="child in item"
          :key="child.id"
          @click="
            $router.push({
              name: 'HomeGoodsdetail',
              query: { goods_id: child.id },
            })
          "
        >
          <img :src="child.image" />
          <h3>{{ child.name }}</h3>
          <p><span>￥</span>{{ child.price }}</p>
          <i>原价：￥{{ child.shop_price }}</i>
        </div>
      </div>

      <div class="g-cart">
        <div class="g-cart-wrap">
          <span class="g-c-oprice"
            >原价：<i>￥{{ cPrice[index].storecost_price }}</i>
          </span>
          <span class="g-c-comprice"
            >套餐价：<i>￥{{ cPrice[index].price }}</i>
          </span>
          <span class="g-c-totalcom"
            >节省: ￥{{
              cPrice[index].storecost_price - cPrice[index].price
            }}</span
          >
        </div>
        <div class="g-c-btn">
          <span @click="addCart(cPrice[index].id)">加入购物车</span>
          <!-- <span @click="goNowShopping(cPrice[index].id)">立即购买</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@get/http";
import { Toast } from "vant";
import { getCartAdd } from "@view/cart/js/";
export default {
  props: {
    detail: {},
  },
  data() {
    return {
      combination: [],
      cPrice: [],
      db: {
        timer: null,
        boo: false,
      },
    };
  },
  watch: {
    detail: {
      deep: true,
      handler() {
        this.getData();
      },
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      if (this.detail.goods_id) {
        let { b_goods_array, bundling_array } = await post(
          "/Goods/get_bundling",
          {
            data: {
              goods_id: this.detail.goods_id,
            },
          }
        );
        this.combination = b_goods_array;
        this.cPrice = bundling_array;
      }
    },
    goNowShopping(bl_id) {
      //立即购买
      let params = {
        buy_now: 0,
        cart_id: `${this.detail.goods_id}|1`,
        bl_id,
      };
      this.$router.push({ name: "MemberBuyStep1", query: params });
    },
    async addCart(bl_id) {
      if (this.db.boo) {
        Toast("你点太快了");
        return false;
      }
      this.db.boo = true;

      await getCartAdd(0, 1, bl_id);

      clearTimeout(this.db.timer);
      this.db.timer = setTimeout(() => {
        this.db.boo = false;
      }, 2000);
      Toast("加入成功");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.g-shop {
  width: rem(710);
  margin: rem(30) auto;
  background: #fff;
}
.g-s-l-title {
  padding: rem(30) rem(24);
  align-items: center;
  span {
    font-size: rem(36);
    font-weight: bold;
    letter-spacing: 2px;
  }
  i {
    color: #a6a6a6;
  }
}
.g-shop-love {
  background: #fff;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 rem(24);
  box-sizing: border-box;
  .g-s-l-list {
    margin-bottom: rem(30);
    &:nth-of-type(3n -1) {
      padding: 0 rem(16);
    }
    width: rem(210);
    > img {
      width: rem(210);
      height: rem(210);
      display: block;
      border-radius: rem(10);
    }
    > h3 {
      @include ell2;
      font-size: rem(28);
    }
    > p {
      color: #f75525;
      font-size: rem(32);
      font-weight: bold;
      span {
        font-size: rem(20);
      }
    }
    i {
      color: grey;
      font-size: rem(20);
      font-weight: normal;
      text-decoration: line-through;
    }
  }
}

.g-cart {
  padding: 0 rem(20);
  .g-cart-wrap {
    > span {
      text-align: right;
      display: block;
      font-size: rem(26);
      &:nth-of-type(1) {
        text-decoration: line-through;
      }
      &:nth-of-type(2) {
        color: rgb(255, 72, 72);
      }
    }
  }
  .g-c-btn {
    text-align: right;
    padding: rem(20) 0;
    span {
      height: rem(60);
      width: rem(160);
      text-align: center;
      line-height: rem(60);
      display: inline-block;
      border-radius: rem(30);
      &:nth-of-type(1) {
        color: rgb(255, 255, 255);
        background: rgb(255, 124, 72);
        margin-right: rem(20);
      }
      &:nth-of-type(2) {
        color: rgb(255, 255, 255);
        background: rgb(255, 72, 72);
      }
    }
  }
}
</style>
