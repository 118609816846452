<template>
  <div class="g-shop">
    <div class="g-shop-info">
      <div class="g-s-header flex-container" @click="gotoStore">
        <img
          class="g-s-h-logo"
          src="http://image.shop.19jiang.cn/home/mofang/alioss_6107944e3a051.jpg"
        />
        <!-- <img
          :src="storeInfo.store_logo ? storeInfo.store_logo : storeInfo.store_avatar"
        /> -->
        <div class="g-s-h-name flex1">
          <h2>{{ storeInfo && storeInfo.store_name }}</h2>
          <p><span>自营</span></p>
        </div>
        <div class="g-s-h-info" v-if="storeInfo && storeInfo.store_credit">
          <p>
            描述相符
            {{ storeInfo && storeInfo.store_credit.store_desccredit.credit }}
            {{
              storeInfo && storeInfo.store_credit.store_desccredit.credit >= 5
                ? "高"
                : "中"
            }}
          </p>
          <p>
            服务态度
            {{ storeInfo && storeInfo.store_credit.store_servicecredit.credit }}
            {{
              storeInfo &&
              storeInfo.store_credit.store_servicecredit.credit >= 5
                ? "高"
                : "中"
            }}
          </p>
          <p>
            发货速度
            {{
              storeInfo && storeInfo.store_credit.store_deliverycredit.credit
            }}
            {{
              storeInfo &&
              storeInfo.store_credit.store_deliverycredit.credit >= 5
                ? "高"
                : "中"
            }}
          </p>
        </div>
      </div>
      <div class="g-s-bottom flex-container">
        <span @click="gotoStore">进店逛逛</span>
        <span @click="$router.push({ name: 'Baby' })">全部宝贝</span>
        <span @click="$router.push({ name: 'Me' })">会员中心</span>
      </div>
    </div>
    <div class="g-s-l-title flex-container" v-if="false">
      <span class="flex1">店铺推荐</span>
      <i>严选产品</i>
    </div>
    <div class="g-shop-love flex-container" v-if="false">
      <div
        class="g-s-l-list"
        v-for="item in info.goods_commend_list"
        :key="item.goods_id"
        @click="
          $router.push({
            name: 'HomeGoodsdetail',
            query: { goods_id: item.goods_id },
          })
        "
      >
        <img :src="item.goods_image_url" />
        <h3>{{ item.goods_name }}</h3>
        <p><span>￥</span>{{ item.goods_price }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      store_id: 2,
    };
  },
  created() {},
  props: {
    storeInfo: {
      type: Object,
    },
    info: {
      type: Object,
    },
  },
  methods: {
    gotoStore() {
      this.$router.push({
        name: "HomeIndex",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.g-shop {
  width: rem(710);
  margin: 0 auto;
  background: #fff;
  .g-shop-info {
    border-radius: rem(30) rem(30) 0 0;
    box-sizing: border-box;
    // padding: rem(14) rem(12);

    background: url("~@image/goods/shop_bg.png") rgba(0, 0, 0, 0.65) no-repeat;
    background-size: rem(710) rem(268);
    margin-top: rem(20);
  }

  .g-s-header {
    border-radius: rem(30) rem(30) 0 0;

    padding: rem(14) rem(12);
    .g-s-h-logo {
      width: rem(100);
      height: rem(100);
      padding: rem(14) rem(12);
    }
    .g-s-h-name {
      padding: rem(14) rem(12);
      h2 {
        font-size: rem(34);
        color: #fff;
      }
      p {
        margin-top: rem(8);
        background: #ef3441;
        display: inline-block;
        padding: 0 rem(10);
        color: #fff;
        // height: rem(30);
        // line-height: rem(30);
        font-size: 10px;
        border-radius: rem(15);
      }
    }
  }
  .g-s-bottom {
    justify-content: center;
    margin-top: rem(20);
    padding-bottom: rem(40);
    span {
      display: inline-block;
      border: 1px solid #fff;
      padding: rem(6) rem(30);
      border-radius: rem(60);
      color: #fff;
      margin: 0 rem(10);
    }
  }
  .g-s-h-info {
    padding: rem(14) rem(12);
    font-size: 10px;
    color: #fff;
  }

  .g-s-l-title {
    padding: rem(30) rem(24);
    align-items: center;
    span {
      font-size: rem(36);
      font-weight: bold;
      letter-spacing: 2px;
    }
    i {
      color: #a6a6a6;
    }
  }
  .g-shop-love {
    background: #fff;
    width: 100%;
    flex-wrap: wrap;
    padding: 0 rem(24);
    box-sizing: border-box;
    .g-s-l-list {
      margin-bottom: rem(30);
      &:nth-of-type(3n -1) {
        padding: 0 rem(16);
      }
      width: rem(210);
      > img {
        width: rem(210);
        height: rem(210);
        display: block;
        border-radius: rem(10);
      }
      > h3 {
        @include ell2;
        font-size: rem(28);
      }
      > p {
        color: #f75525;
        font-size: rem(32);
        font-weight: bold;
        span {
          font-size: rem(20);
        }
      }
    }
  }
}
</style>
