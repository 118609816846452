<template>
  <div
    class="review"
    v-if="(review && review.total != 0) || (buyer && buyer.total != 0)"
  >
    <div v-if="review && review.total != 0">
      <div class="flex-container reivew-title">
        <h1>用户评价</h1>
        <i class="flex1">{{ review.total }}</i>
        <p @click="$router.push({ name: 'HomeGoodsReview', query: { c_id } })">
          {{ (review.avg_scores / 5) * 100 }}% 满意
        </p>
      </div>
      <oReview :review="review.data[0]" v-if="review && review.data"></oReview>
      <div
        class="checkReview"
        @click="$router.push({ name: 'HomeGoodsReview', query: { c_id } })"
      >
        查看全部评论
      </div>
    </div>

    <div
      class="flex-container reivew-title "
      :class="review.total != 0 ? 'bolder' : ''"
      v-if="buyer.total != 0"
    >
      <h1>买家秀</h1>
      <i class="flex1">{{ buyer.total }}</i>
      <p @click="$router.push({ name: 'HomeGoodsBuyershow', query: { c_id } })">
        查看全部
      </p>
    </div>
    <div class="member_showImg_ul flex-container">
      <div
        class="member_showImg_li"
        v-for="(item, index) in buyerShow"
        :key="index"
        @click="$router.push({ name: 'HomeGoodsBuyershow', query: { c_id } })"
      >
        <img
          src="@image/goods/review_play.png"
          class="r_icon_play"
          v-if="item.type == 2"
        />

        <van-image class="r_reivewImg" :src="item.url" fit="cover" />
      </div>
    </div>
  </div>
</template>

<script>
import oReview from "./reviewComponents";
import { Image as VanImage } from "vant";
import { post } from "@get/http";
export default {
  props: {
    c_id: {},
  },
  components: {
    oReview,
    VanImage,
  },
  async created() {
    this.getReviewData();

    this.review = await this.getReviewData();
    this.buyer = await this.getReviewData(2, 4);
    if (this.buyer.total != 0) {
      let data = this.buyer.data,
        arr = [];
      for (let index = 0; index < data.length; index++) {
        if (arr.length >= 4) {
          break;
        }
        for (let i = 0; i < data[index].geval_image.length; i++) {
          arr.push(data[index].geval_image[i]);

          if (arr.length >= 4) {
            break;
          }
        }
      }
      this.buyerShow = arr;
    }
  },
  data() {
    return {
      review: [],
      buyer: [],
      buyerShow: [],
    };
  },
  methods: {
    async getReviewData(state = 1, pageSize = 1) {
      return await post("/Memberevaluate/show", {
        data: {
          commonid: this.c_id, //商品commonid
          type: 0, //默认0)显示全部 1)只看有图 2)只看文字
          state, //1)评论 2)买家秀 其他)10001错误报异常
          order: 0, //默认0)ID倒序 1)点赞倒序 2)点赞正序
          page: 1,
          pageSize,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.review {
  background: #fff;
  width: rem(710);
  border-radius: rem(16);
  margin: rem(30) auto 0;
  padding: rem(30) rem(24);
  box-sizing: border-box;
}
.bolder {
  color: #efefef;
  border-top: 1px solid #efefef;
  padding-top: rem(30);
}
.reivew-title {
  color: #333;
  margin-bottom: rem(30);

  h1 {
    font-size: rem(28);
    font-weight: bold;
  }
  i {
    font-size: rem(18);
    // padding-top: rem(12);
    margin-left: rem(10);
    margin-bottom: rem(3);
    align-self: flex-end;
  }
  p {
    color: #999;
    font-size: rem(24);

    background: url("~@image/goods/review_more.png") right rem(8) no-repeat;
    background-size: rem(20) rem(20);
    padding-right: rem(20);
  }
}

.checkReview {
  width: rem(200);
  height: rem(60);
  border: rem(2) solid #c7c7c7;

  border-radius: rem(32);
  margin: rem(20) auto rem(30);
  text-align: center;
  line-height: rem(60);
}

.member_showImg_ul {
  .member_showImg_li {
    position: relative;
    margin-right: rem(10);
    .r_icon_play {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      margin: auto;
      width: rem(50);
      height: rem(50);
    }
    .r_reivewImg {
      width: rem(158);
      height: rem(158);
      border-radius: rem(8);
    }
  }
}
</style>
