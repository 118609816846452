import {
  post
} from '@get/http'


export const getGoodsDetail = (goods_id, token) => {
  /****
   *  @getGoodsDetail 获取商品详情信息
   *  @param {string} goods_id 商品ID
   *  @param {string} token 用户token
   *  @return {Promise}
   */
  return post('/goods/goods_detail_v3', {
    data: {
      goods_id,
      token
    },

  }, {
    result: true,
    toast: false,
    resultKey: 'results'
  })
}