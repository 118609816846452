<template>
  <div>
    <van-image-preview v-model="show" :images="images" @change="onChange">
      <template v-slot:index>{{ index + 1 }} / {{ images.length }}</template>
    </van-image-preview>
    <div class="flex-container r_user">
      <img :src="review.goodsimage_full_path" class="r_userHeadimg" />
      <div class="r_userInfo">
        <h3>{{ review.geval_frommembername }}</h3>
        <p>{{ review.geval_addtime | time }} | {{ review.geval_goodsname }}</p>
      </div>
    </div>
    <p class="r_context">
      {{ review.geval_content }}
    </p>
    <!-- <span class="r_showContext" v-if="iscontext">展开</span> -->
    <ul class="r_ul flex-container">
      <template v-for="(item, index) in review.geval_image">
        <li
          class="r_li"
          v-if="item.type == 2"
          :key="index"
          @click="showVideoPopup(item.url)"
        >
          <img src="@image/goods/review_play.png" class="r_icon_play" />
          <img
            :src="
              `${item.url}?spm=qipa250&x-oss-process=video/snapshot,t_0000,f_jpg,w_500,h_500,m_fast`
            "
            class="r_reivewImg"
          />
        </li>
        <li class="r_li" v-else :key="index" @click="showImg">
          <img :src="item.url" class="r_reivewImg" />
        </li>
      </template>
    </ul>

    <div class="r_good flex-container" v-if="isgood">
      <img
        src="@image/goods/review_goods_act.png"
        v-if="review.liked"
        @click="setGoodAction(review.geval_id)"
      />
      <img
        src="@image/goods/review_goods.png"
        v-else
        @click="setGoodAction(review.geval_id)"
      />
      <i @click="setGoodAction(review.geval_id)">{{ review.like }}</i>
    </div>

    <van-popup
      round
      v-model="showVideo"
      position="bottom"
      :style="{ height: '70%' }"
      closeable
      @close="closeVideoPopup"
    >
      <video
        style="width: 100%; height: 100%"
        controls
        webkit-playsinline="true"
        playsinline="true"
        :poster="
          `${VideoUrl}?spm=qipa250&x-oss-process=video/snapshot,t_0000,f_jpg,w_500,h_500,m_fast`
        "
        :src="VideoUrl"
        class="video"
        id="video"
        loop
      ></video>
    </van-popup>
  </div>
</template>

<script>
import { post } from "@get/http";
import { ImagePreview, Popup, Toast } from "vant";

export default {
  data() {
    return {
      show: false,
      showVideo: false,
      index: 0,
      images: [],
      VideoUrl: "",
      timer: null,
      goodTime: false,
      goodTimer: null,
    };
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    vanPopup: Popup,
  },
  props: {
    review: {},

    isgood: {
      default: false,
      type: Boolean,
    },
    iscontext: {
      default: false,
      type: Boolean,
    },
  },
  created() {
    if (this.review?.geval_image) {
      for (let index = 0; index < this.review.geval_image.length; index++) {
        if (this.review.geval_image[index].type == 1) {
          this.images.push(this.review.geval_image[index].url);
        }
      }
    }
  },
  methods: {
    closeVideoPopup() {
      document.getElementById("video").pause();
    },
    showVideoPopup(url) {
      this.showVideo = true;
      this.VideoUrl = url;
      // document.getElementById("video").play();

      clearTimeout(this.timer);
      this.timer = setTimeout(function() {
        document.getElementById("video").play();
      }, 500);
    },
    showImg() {
      this.show = true;
    },
    onChange(index) {
      this.index = index;
    },
    async setGoodAction(geval_id) {
      if (this.goodTime == true) {
        Toast("你点太快了");
        return false;
      }
      this.goodTime = true;
      // 点赞
      let res = await post("/Memberevaluate/like", {
        data: {
          geval_id,
        },
      });
      Toast("成功");
      clearTimeout(this.goodTimer);
      this.goodTimer = setTimeout(() => {
        this.goodTime = false;
      }, 1500);
      this.review.liked = !this.review.liked;
      if (this.review.liked == true) {
        this.review.like++;
      } else {
        this.review.like--;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.r_user {
  margin-bottom: rem(20);
  .r_userHeadimg {
    margin-right: rem(10);
    width: rem(68);
    height: rem(68);
    border-radius: 50%;
    display: block;
  }
  .r_userInfo {
    h3 {
      font-size: rem(26);
      color: #333;
    }
    p {
      color: #999;
      font-size: rem(22);
    }
  }
}
.r_context {
  // @include ell2;
  color: #333;
  font-size: rem(26);
  line-height: rem(36);
  margin-bottom: rem(10);
}

.r_ul {
  flex-wrap: wrap;
  padding-top: rem(10);
  .r_li {
    position: relative;
    margin-right: rem(10);
    margin-bottom: rem(10);
    &:nth-of-type(4n - 1) {
      margin-right: 0;
    }
    .r_icon_play {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      margin: auto;
      width: rem(50);
      height: rem(50);
    }
    .r_reivewImg {
      width: rem(188);
      height: rem(188);
      border-radius: rem(8);
    }
  }
}

.r_good {
  text-align: right;
  justify-content: flex-end;
  margin-top: rem(30);
  img {
    width: reM(27);
    height: rem(27);
  }
  i {
    font-size: rem(26);
    color: #666;
    display: inline-block;
    margin-left: rem(6);
  }
}

.r_showContext {
  color: #1a92e9;
  font-size: rem(26);
  margin-bottom: rem(10);
  display: block;
}
</style>
