<template>
  <div class="g-active flex-container">
    <div class="g-a-left flex1">
      <div class="info">
        <div class="title">{{ detail.title }}</div>
        <!-- <div class="price">
          活动价￥<span>{{ detail.promotion_price }}</span
          ><b>元</b>
        </div> -->
        <div class="data flex-container">
          <i>￥</i>
          <p>{{ detail.goods_marketprice }}</p>
          <span>元</span>
          <div>
            {{ detail.promotion_type == "presale" ? "定金" : "活动价" }}
            ￥<span ref="price">{{ detail.promotion_price }}</span
            ><b>元</b>
          </div>
        </div>
      </div>
    </div>

    <div
      class="g-a-right"
      v-if="detail.promotion_is_allow && detail.promotion_is_allow == 0"
    >
      <div class="timeTitle" style="padding-top:20px;">活动未开始</div>
    </div>

    <div
      class="g-a-right"
      v-else-if="detail.promotion_is_allow && detail.promotion_is_allow == 2"
    >
      <div class="timeTitle" style="padding-top:20px;">活动已结束</div>
    </div>

    <div class="g-a-right" v-else>
      <div class="timeTitle">距优惠结束</div>
      <div class="flex-container">
        <span>{{ hr }}</span> <i>:</i> <span>{{ min }}</span> <i>:</i>
        <span>{{ sec }}</span>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    detail: {},
    times: {
      default: false,
    },
  },
  data: () => ({
    day: 0,
    hr: 0,
    min: 0,
    sec: 0,
    timer: null,
  }),
  mounted: function() {
    if (this.times) {
      this.countdown();
    }
  },
  created() {},
  watch: {},
  methods: {
    countdown: function() {
      const end = this.times * 1000;

      // const end = Date.parse(new Date('2020-12-03 00:00:00'))
      // console.log(end,this.times*1000)
      const now = Date.parse(new Date());
      const msec = end - now;
      // if (!this.times || msec >) {
      //         return false;
      //       }
      let day = parseInt(msec / 1000 / 60 / 60 / 24);
      let hr = parseInt((msec / 1000 / 60 / 60) % 24);
      let min = parseInt((msec / 1000 / 60) % 60);
      let sec = parseInt((msec / 1000) % 60);

      this.day = day;
      let _hr = day * 24 + hr;
      this.hr = _hr > 9 ? _hr : "0" + _hr;
      this.min = min > 9 ? min : "0" + min;
      this.sec = sec > 9 ? sec : "0" + sec;
      const that = this;
      this.timer = setTimeout(function() {
        if (msec <= 0) {
          clearTimeout(this.timer);
        } else {
          that.countdown();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.g-active {
  height: rem(180);
  background: url("~@image/goods/goods_bg.png") no-repeat;
  background-size: 100% rem(180);
  padding: rem(24);
  box-sizing: border-box;
  .g-a-left {
    .info {
      color: #fff;
      .price {
        display: inline-block;
        height: rem(56);
        font-size: rem(30);
        line-height: rem(56);
        border-radius: rem(28);
        background: #fff;
        color: #ee0a24;
        padding: 0 rem(24);
        span {
          font-size: rem(40);
        }
      }
      .title {
        font-size: rem(34);
      }
      .data {
        align-items: flex-end;
        > i {
          font-size: rem(24);
          text-decoration: line-through;
        }
        > p {
          font-size: rem(30);
          text-decoration: line-through;
        }
        > span {
          font-size: rem(26);
          text-decoration: line-through;
        }
        div {
          margin-left: rem(6);
          height: rem(56);
          font-size: rem(30);
          line-height: rem(56);
          border-radius: rem(28);
          background: #fff;
          color: #ee0a24;
          padding: 0 rem(24);
          span {
            font-size: rem(40);
          }
        }
      }
    }
  }
  .g-a-right {
    .timeTitle {
      font-size: rem(30);
      color: #fff;
      text-align: right;
    }
    > .flex-container {
      margin-top: rem(12);
      span {
        // width: rem(46);
        padding: 0 rem(4);
        height: rem(46);
        background: #000;
        border-radius: rem(8);
        text-align: center;
        line-height: rem(46);
        font-size: rem(26);
        color: #fff;
      }
      i {
        margin: 0 rem(6);
        font-size: rem(26);
      }
    }
  }
}
</style>
